// --------------------------------
//					      VARIABLES
// --------------------------------

$text: #3c3b32;
$bg: #fff;

$orange: #f37121;
$dark: #3c3b32;
$yellow: #fccf1a;
$blue: #0091d4;
$green: #4daf47;

$padding: 30px;

// padding used in foundation
$bump: 0.9375em;
