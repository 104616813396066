// --------------------------------
//					   		 MIXINS
// --------------------------------

// Reset <li> elements
@mixin li-reset {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

// Remove text from elements
@mixin rm-text {
  text-align: left;
  text-indent: -9999px;
  *font-size: 0;
  *line-height: 0px;
}

// Remove li bottom margin
@mixin li-margin($columns, $marginBottom) {
  &:nth-last-child(-n + #{$columns}):nth-child(#{$columns}n + 1),
  &:nth-last-child(-n + #{$columns}):nth-child(#{$columns}n + 1) ~ li {
    margin-bottom: $marginBottom;
  }
}

// Centered background images
@mixin bg-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

// IE 9+ only
@mixin ie($property, $value) {
  @media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
    #{$property}: #{$value};
  }
}

@mixin btn {
  padding: 15px 30px 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 1.2;
  font-size: 0.8em;
  background: $orange;
  color: #fff;
  border-radius: 3px;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: $text;
    color: #fff;
  }
}
