/* --- Fonts --- */
/* --- Media queries --- */
a {
  transition: all 0.2s ease-out;
}

.front .featured {
  position: relative;
  z-index: 2;
}
.front .featured li {
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.25);
}
.front .support-wrapper {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #0091d4;
  background-size: cover;
  padding: 90px 0;
  color: #fff;
  position: relative;
}
@media (max-width: 599px) {
  .front .support-wrapper {
    padding-bottom: 30px;
  }
}
.front .support-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #0091d4;
  opacity: 0.7;
}
.front .support-intro {
  margin-bottom: 30px;
}
.front .support-intro h2 {
  color: #fff;
  margin-top: 0;
}
.front .support-intro p {
  font-size: 18px;
  line-height: 28px;
}
.front .signposts {
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.front .homepage-signpost {
  position: relative;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  width: 40%;
  overflow: hidden;
}
@media (max-width: 1024px) {
  .front .homepage-signpost {
    width: 48%;
  }
}
@media (max-width: 599px) {
  .front .homepage-signpost {
    width: 100%;
    margin-bottom: 30px;
  }
}
.front .homepage-signpost img {
  width: 100%;
  height: auto;
  border-radius: 3px;
}
.front .homepage-signpost .signpost__thumb {
  display: block;
}
.front .homepage-signpost .signpost__content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.front .homepage-signpost .signpost__content h3 {
  margin: 0;
  display: inline-block;
}
.front .homepage-signpost .signpost__content h3 a {
  background: #fff;
  border-radius: 3px;
  padding: 10px 15px 5px;
  color: #3c3b32;
  text-transform: uppercase;
  position: relative;
  top: 15px;
}
@media (max-width: 319px) {
  .front .homepage-signpost .signpost__content h3 a {
    font-size: 0.8em;
  }
}
.front .homepage-signpost .signpost__content .button.info {
  float: none;
  display: inline-block;
}
.front .homepage-signpost .signpost__content .button.info:hover {
  background: #3c3b32;
}
.front .what-else {
  margin: 0 0 1em;
  padding-top: 60px;
}
.front .what-else h2 {
  margin: 0 0 1em;
}
.front .latest {
  overflow: hidden;
}
.front .latest h2 {
  margin: 1em 0;
}

.banner-image img {
  width: 100%;
  height: auto;
}

.intro__wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.member__signposts {
  top: -23px;
  text-align: center;
}
.member__signposts h2 {
  background: #3c3b32;
  color: #fff;
  display: inline-block;
  margin: 0 auto 30px;
  padding: 12px 90px 10px;
  text-transform: uppercase;
  font-size: 1.2em;
  line-height: 1.2;
}

.membership-signpost {
  float: left;
  width: 48.5%;
  display: block;
  position: relative;
  border-radius: 3px;
  background: #555555;
  overflow: hidden;
}
.membership-signpost::before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-image: url("../images/mask-gold.svg");
  height: 100%;
  width: 100%;
  z-index: 2;
}
.membership-signpost:first-child {
  margin-right: 3%;
  background: #3d3b32;
}
.membership-signpost:first-child::before {
  background-image: url("../images/mask-silver.svg");
}
.membership-signpost img {
  width: 100%;
  height: 100%;
  opacity: 0.35;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.membership-signpost__content {
  position: relative;
  z-index: 2;
  height: 100%;
  width: 100%;
  padding: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}
.membership-signpost__content h3 {
  margin-top: 0;
}
.membership-signpost__content h3 a {
  color: #fff;
  text-transform: uppercase;
  font-size: 1.2em;
}
.membership-signpost__content h3 a:hover {
  color: #f37121;
}
.membership-signpost__content h4 {
  margin-top: 0;
  color: #fff;
}
.membership-signpost__content p {
  margin: 45px;
}
.membership-signpost__content .button.info {
  padding-bottom: 10px;
}
.membership-signpost__content .button.info:hover {
  background: #3c3b32;
}

.membership-join {
  padding: 15px 30px 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 1.2;
  font-size: 0.8em;
  background: #f37121;
  color: #fff;
  border-radius: 3px;
  transition: all 0.2s ease-out;
  background: #0091d4;
}
.membership-join:hover {
  background-color: #3c3b32;
  color: #fff;
}
.membership-join:hover {
  background: #3c3b32;
}

.choose.second {
  margin-bottom: 60px;
}

.enjoy__wrapper {
  background: #d8d8d8;
  padding: 30px 0;
  margin: 45px 0;
}

.enjoy__inner img {
  float: right;
  margin-left: 90px;
  transform: scale(1.5);
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  max-width: 30%;
  transform-origin: 100% 50%;
}

.enjoy__content {
  max-width: 40%;
}
.enjoy__content h2 {
  margin-top: 0;
  line-height: 1.2;
}

.quote__wrapper {
  text-align: center;
}
.quote__wrapper blockquote {
  font-family: "pt_sansbold";
  font-style: italic;
  font-size: 2.06em;
  line-height: 1.2;
  margin: 15px 0;
}
.quote__wrapper blockquote p {
  margin: 0;
}
.quote__wrapper cite {
  font-family: "pt_sansregular";
  font-weight: bold;
  font-style: normal;
  color: #3c3b32;
}
.quote__wrapper cite span {
  font-weight: normal;
}

.benefits__wrapper {
  text-align: center;
  margin-bottom: 45px;
}
.benefits__wrapper h2 {
  margin: 60px 0 0;
}
.benefits__wrapper .entity {
  float: left;
  width: 31.3333%;
  margin-right: 3%;
  text-align: left;
}
.benefits__wrapper .entity:nth-of-type(3n + 3) {
  margin-right: 0;
}
.benefits__wrapper .entity img {
  width: 100%;
  height: auto;
}
.benefits__wrapper .entity h4 {
  margin: 20px 0 10px;
}
.benefits__wrapper .entity p {
  margin: 0;
}

.page-whats-on .large-3 > h3 {
  margin: 20px 0;
}

.sidebar-signpost {
  background: #3c3b32;
  color: #fff;
  margin-bottom: 15px;
  border-radius: 3px;
  overflow: hidden;
}

.signpost__thumb {
  border-radius: 3px;
  position: relative;
}
.signpost__thumb h3 {
  position: absolute;
  bottom: 15px;
  left: 15px;
  background: #fff;
  border-radius: 3px;
  padding: 10px 10px 8px;
  margin: 0;
}
.signpost__thumb h3 a {
  color: #3c3b32;
  text-transform: uppercase;
  font-size: 0.7em;
}

.signpost__content {
  padding: 15px;
}
.signpost__content p {
  margin-top: 0;
}
.signpost__content .button.info {
  float: none;
  display: inline-block;
  padding-bottom: 12px;
}

.large-3 .pane-gallery-current {
  clear: both;
  padding: 0;
  margin: 0 0 15px;
}

#SpektrixIFrame {
  min-height: 80vh;
}