// --------------------------------
//					   		  SETUP
// --------------------------------

/* --- Fonts --- */

@mixin font-body {
  font-family: 'pt_sansregular';
}

@mixin font-italic {
  font-family: 'pt_sansitalic';
}

@mixin font-bold {
  font-family: 'pt_sansbold';
}

/* --- Media queries --- */

//	USAGE:
//
//	@include media(">tabletland", "<=1150px") {
//		font-size:4.0rem;
//	}
//
//	@include media("<mobile") {
//		width:100%;
//	}

$breakpoints: (
  vsmall: 350px,
  small: 480px,
  mobile: 768px,
  tabletport: 800px,
  tabletland: 1025px,
);
