// --------------------------------
//					   		 STYLES
// --------------------------------
a {
  transition: all 0.2s ease-out;
}

.front {
  .featured {
    position: relative;
    z-index: 2;

    li {
      box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.25);
    }
  }

  .support-wrapper {
    @include bg-img;
    background-color: $blue;
    background-size: cover;
    padding: $padding * 3 0;
    color: #fff;
    position: relative;

    @include media('<600px') {
      padding-bottom: 30px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $blue;
      opacity: 0.7;
    }
  }
  .support-intro {
    margin-bottom: $padding;

    h2 {
      color: #fff;
      margin-top: 0;
    }

    p {
      font-size: 18px;
      line-height: 28px;
    }
  }
  .signposts {
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .homepage-signpost {
    position: relative;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    width: 40%;
    overflow: hidden;

    @include media('<tabletland') {
      width: 48%;
    }

    @include media('<600px') {
      width: 100%;
      margin-bottom: 30px;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 3px;
    }

    .signpost__thumb {
      display: block;
    }

    .signpost__content {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding: $padding/2 $padding/3;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      h3 {
        margin: 0;
        display: inline-block;

        a {
          background: #fff;
          border-radius: 3px;
          padding: $padding/3 $padding/2 $padding/6;
          color: $text;
          text-transform: uppercase;
          position: relative;
          top: $padding/2;

          @include media('<320px') {
            font-size: 0.8em;
          }
        }
      }

      .button.info {
        float: none;
        display: inline-block;

        &:hover {
          background: $text;
        }
      }
    }
  }

  .what-else {
    margin: 0 0 1em;
    padding-top: $padding * 2;

    h2 {
      margin: 0 0 1em;
    }
  }

  .latest {
    overflow: hidden;

    h2 {
      margin: 1em 0;
    }
  }
}

.banner-image {
  img {
    width: 100%;
    height: auto;
  }
}

.intro__wrapper {
  padding-top: $padding;
  padding-bottom: $padding;
  text-align: center;
}

.member__signposts {
  top: -23px;
  text-align: center;

  h2 {
    background: $text;
    color: #fff;
    display: inline-block;
    margin: 0 auto $padding;
    padding: 12px $padding * 3 $padding/3;
    text-transform: uppercase;
    font-size: 1.2em;
    line-height: 1.2;
  }
}

.membership-signpost {
  float: left;
  width: 48.5%;
  display: block;
  position: relative;
  border-radius: 3px;
  background: #555555;
  overflow: hidden;

  &::before {
    @include bg-img;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background-image: url('../images/mask-gold.svg');
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  &:first-child {
    margin-right: 3%;
    background: #3d3b32;

    &::before {
      background-image: url('../images/mask-silver.svg');
    }
  }

  img {
    width: 100%;
    height: 100%;
    opacity: 0.35;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.membership-signpost__content {
  position: relative;
  z-index: 2;
  height: 100%;
  width: 100%;
  padding: $padding * 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #fff;

  h3 {
    margin-top: 0;

    a {
      color: #fff;
      text-transform: uppercase;
      font-size: 1.2em;

      &:hover {
        color: $orange;
      }
    }
  }

  h4 {
    margin-top: 0;
    color: #fff;
  }

  p {
    margin: $padding * 1.5;
  }

  .button.info {
    padding-bottom: $padding/3;

    &:hover {
      background: $text;
    }
  }
}

.membership-join {
  @include btn;
  background: $blue;

  &:hover {
    background: $text;
  }
}

.choose.second {
  margin-bottom: $padding * 2;
}

.enjoy__wrapper {
  background: #d8d8d8;
  padding: $padding 0;
  margin: $padding * 1.5 0;
}

.enjoy__inner {
  img {
    float: right;
    margin-left: $padding * 3;
    transform: scale(1.5);
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    max-width: 30%;
    transform-origin: 100% 50%;
  }
}

.enjoy__content {
  max-width: 40%;

  h2 {
    margin-top: 0;
    line-height: 1.2;
  }
}

.quote__wrapper {
  text-align: center;

  blockquote {
    @include font-bold;
    font-style: italic;
    font-size: 2.06em;
    line-height: 1.2;
    margin: $padding/2 0;

    p {
      margin: 0;
    }
  }

  cite {
    @include font-body;
    font-weight: bold;
    font-style: normal;
    color: $text;

    span {
      font-weight: normal;
    }
  }
}

.benefits__wrapper {
  text-align: center;
  margin-bottom: $padding * 1.5;

  h2 {
    margin: $padding * 2 0 0;
  }

  .entity {
    float: left;
    width: 31.3333%;
    margin-right: 3%;
    text-align: left;

    &:nth-of-type(3n + 3) {
      margin-right: 0;
    }

    img {
      width: 100%;
      height: auto;
    }

    h4 {
      margin: 20px 0 10px;
    }

    p {
      margin: 0;
    }
  }
}

.page-whats-on {
  .large-3 {
    & > h3 {
      margin: 20px 0;
    }
  }
}

.sidebar-signpost {
  background: $text;
  color: #fff;
  margin-bottom: $padding/2;
  border-radius: 3px;
  overflow: hidden;
}

.signpost__thumb {
  border-radius: 3px;
  position: relative;

  h3 {
    position: absolute;
    bottom: $padding/2;
    left: $padding/2;
    background: #fff;
    border-radius: 3px;
    padding: 10px 10px 8px;
    margin: 0;

    a {
      color: $text;
      text-transform: uppercase;
      font-size: 0.7em;
    }
  }
}
.signpost__content {
  padding: $padding/2;

  p {
    margin-top: 0;
  }

  .button.info {
    float: none;
    display: inline-block;
    padding-bottom: 12px;
  }
}

.large-3 .pane-gallery-current {
  clear: both;
  padding: 0;
  margin: 0 0 15px;
}


#SpektrixIFrame {
  min-height: 80vh;
}
